<template>
    <b-card title="Add Subscription">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- Name -->
                    <b-col cols="12">
                        <b-form-group label="Title" label-for="title">
                            <validation-provider name="title" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="title" v-model="title" :state="errors.length > 0 ? false : null"
                                        placeholder="Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Name -->
                    <b-col cols="12">
                        <b-form-group label="Add to intro insider" label-for="checkedNames">
                            <b-form-checkbox id="checkedNames" v-model="checkedNames" name="checkedNames" value="1"
                                unchecked-value="0">
                                Add to intro insider
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>

                    <!-- Description -->
                    <b-col cols="12">
                        <b-form-group label="Description" label-for="description">
                            <validation-provider name="description" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <!-- <b-form-input id="fh-description" v-model="description" type="description"
                                        :state="errors.length > 0 ? false : null" placeholder="description" /> -->
                                    <b-form-textarea id="description" v-model="description"
                                        :state="errors.length > 0 ? false : null" placeholder="Enter something..." rows="5"
                                        size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Tag Line -->
                    <b-col cols="12">
                        <b-form-group label="Tag Line" label-for="tag line">
                            <validation-provider name="tag line" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="tag_line" v-model="tag_line" :state="errors.length > 0 ? false : null"
                                        placeholder="Tag Line" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Country -->
                    <b-col cols="12">
                        <b-form-group label="Select Country" label-for="country">
                            <validation-provider name="Country" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="country" v-model="selectedCountry" :options="countryOptions"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Profile Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewImg" :class="previewImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Profile Image -->
                    <b-col cols="12">
                        <b-form-group label="Profile Image" label-for="profile">
                            <validation-provider name="Profile Image" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="profile" v-model="profileImg" :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Price -->
                    <b-col cols="12">
                        <b-form-group label="Price" label-for="price">
                            <validation-provider name="price" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="price" v-model="price" :state="errors.length > 0 ? false : null"
                                        placeholder="Price" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Discount -->
                    <b-col cols="12">
                        <b-form-group label="Discount (%)" label-for="discount">
                            <validation-provider name="discount" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="discount" v-model="discount" :state="errors.length > 0 ? false : null"
                                        placeholder="Discount (%)" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Vat -->
                    <b-col cols="12">
                        <b-form-group label="Vat (%)" label-for="vat">
                            <validation-provider name="vat" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input id="vat" v-model="vat" :state="errors.length > 0 ? false : null"
                                        placeholder="Vat (%)" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Tag -->
                    <!-- <b-col cols="12">
                        <b-form-group label="Tag" label-for="tag">
                            <validation-provider name="tag" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                         <b-form-input id="tag" v-model="tag"
                                        :state="errors.length > 0 ? false : null" placeholder="Tag" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->

                    <!-- <div class="form-group mb-4">
                        <label class="col-md-12 p-0">Tag</label>
                        <div class="col-md-12 p-0 collapse-col">
                            <div v-for="(find, index) in finds" :key="find.id">
                                <input v-model="find.value" class="form-control form-control-lg">
                                <span  class="delete btn button-white uppercase fas fa-times" @click="deleteFind(index)">
                                </span>
                            </div>
                            <span id="add" @click="addFind" class="btn add-more button-yellow uppercase fas fa-plus"></span>
                        </div>
                    </div> -->

                    <!-- Tag -->
                    <b-col cols="12">
                        <b-form-group label="Tag" label-for="Tag">
                            <div v-for="(find, index) in finds" :key="find.id">
                                <b-input-group>
                                    <!-- <b-input-group-prepend>
                                <b-button variant="outline-info">Button</b-button>
                                </b-input-group-prepend> -->

                                    <b-form-input v-model="find.value" min="0.00"></b-form-input>

                                    <b-input-group-append>
                                        <!-- <b-button variant="outline-secondary">Button</b-button> -->
                                        <b-button @click="deleteFind(index)" variant="outline-primary">Delete</b-button>
                                        <b-input-group-text>
                                            <b-icon icon="x" />
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                                <!-- <input v-model="find.value" class="form-control form-control-lg"> -->
                                <!-- <span  class="delete btn button-white uppercase fas fa-times" @click="deleteFind(index)">
                                    </span> -->
                                <!-- <b-button @click="deleteFind(index)" variant="danger">
                                    <plus-icon size="1.2x" class="custom-class"></plus-icon>delete
                                </b-button> -->
                            </div>
                            <div id="add"></div>
                            <b-col>
                                <b-button @click="addFind" variant="success">
                                    <plus-icon size="1.2x" class="custom-class"></plus-icon>TAG
                                </b-button>
                            </b-col>
                        </b-form-group>
                    </b-col>

                     <!-- <b-col cols="12">
  <b-form-group label="Category" label-for="category-select">
    <validation-provider name="Category" rules="required" #default="{ errors }">
      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
        <b-input-group-prepend is-text>
          <feather-icon icon="TagIcon" />
        </b-input-group-prepend>

        <!-- Dropdown for selecting categories -->
        <!-- <b-form-select id="category-select" v-model="selectedCategory" :state="errors.length > 0 ? false : null">
          <option v-for="category in rows" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </b-form-select>
      </b-input-group>
      
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</b-col> -->

                    <!-- Meta Title -->
                    <b-col cols="12">
                        <b-form-group label="Meta Title" label-for="meta title">
                            <validation-provider name="meta_title" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_title" v-model="meta_title"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Description -->
                    <b-col cols="12">
                        <b-form-group label="Meta Description" label-for="meta description">
                            <validation-provider name="meta_description" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>

                                    <b-form-textarea id="meta_description" v-model="meta_description"
                                        placeholder="Enter something..." rows="5" size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Keyword -->
                    <b-col cols="12">
                        <b-form-group label="Meta Keyword" label-for="meta Keyword">
                            <validation-provider name="meta_keyword" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_keyword" v-model="meta_keyword"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Keyword" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>


                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="validationForm"
                            variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    required
} from '@validations'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            title: '',
            checkedNames: '',
            description: '',
            previewImg: '',
            price: '',
            selectedCategory:'',
            blogshowoption: '',
            discount: '',
            vat: '',
            tag_line: '',
            tag: '',
              rows: [], 
            finds: [],
            meta_title: '',
            meta_description: '',
            meta_keyword: '',
            profileImg: '',
            selectedoptions: null,
            selectedCountry: null,
            options: [
                { 'value': null, text: 'Please select Publish / Unpblish' },
                { 'value': 0, 'text': 'Unpublished' },
                { 'value': 1, 'text': 'published' }
            ],
            countryOptions: [
                { 'value': null, text: 'Please select a country' },
            ]
        }
    },
    watch: {
    selectedCategory(newVal) {
      // When the selected category changes, filter and store only the selected one in rows
      const selectedData = this.rows.find((category) => category.id === newVal);
      if (selectedData) {
        this.rows = [selectedData]; // Store only the selected category in rows
      }
    },
  },
    created() {
        this.getCountries();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        addFind: function () {
            this.finds.push({ value: '' });
        },
        deleteFind: function (index) {
            console.log(index);
            console.log(this.finds);
            this.finds.splice(index, 1);
        },
        add() {
            console.log('hghghf');

            event.preventDefault();
            // var field_forms = '<fieldset>' +  
            //                     '<div class="form-group">' +
            //                     '<label class="control-label" for="textinput">Name</label>' +
            //                       '<div class="col-md-12 margin-bottom collapse-col">' +
            //                       '<input name="tag[]" type="text" placeholder="Enter name" class="form-control input-md"></input>' +
            //                             '<button class="delete btn button-white uppercase fas fa-times"></button>' +
            //                       '</div>' +
            //                '</div>' +
            //            '</fieldset>';


            $(field_forms).insertBefore($("#add"));
        },
        reset() {
            this.title = '',
                this.description = '',
                this.previewImg = '',
                this.price = '',
                this.checkedNames = '',
                this.blogshowoption = '',
                this.discount = '',
                this.vat = '',
                this.tag_line = '',
                this.tag = '',
                this.meta_title = '',
                this.meta_description = '',
                this.meta_keyword = '',
                this.profileImg = '',
                this.selectedoptions = null,
                this.selectedCountry = null;
        },
        getCountries() {
            this.countries = [];
            db.collection("countries")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.countryOptions.push({
                            value: doc.id,
                            text: doc.data().country,
                            slug: doc.data().slug,
                        });
                        console.log(doc.id, " => ", doc.data());
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },
        onImageChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Profile Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();
                    fileReader.onload = (event) => {
                        this.previewImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewImg = '';
                }
            });
        },
        readCategories() {
    store.dispatch('app/commitActivateLoader');
    this.rows = []; // Clear previous category data
    db.collection("subcategory")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.rows.push({
            id: doc.id,
            name: doc.data().name // Ensure 'name' exists in Firestore
          });
        });
        store.dispatch('app/commitDeactivateLoader');
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        store.dispatch('app/commitDeactivateLoader');
      });
  },
        submit() {
            store.dispatch('app/commitActivateLoader');
            if (this.checkedNames == true) {
                this.blogshowoption = 1;
            } else if (this.checkedNames == false) {
                this.blogshowoption = 0;
            }

            var country = "QKUvS13wB9Zo3eHZb81W"
            var currency = [];
            db.collection("currency")
                // .where("country_id", "==", this.item.country ?? country)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().country_id == this.selectedCountry) {
                            // console.log(doc.data())
                            currency = doc.data().code
                        }
                        if (doc.data().country_id == country) {
                            currency = doc.data().code
                        }
                        // console.log(currency)
                    })
                    console.log(currency)

                    var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
                    db.collection("subscriptions")
                        .add({
                            country: this.selectedCountry,
                            currency: currency,
                            title: this.title,
                            tag: this.rows,
                            tag_line: this.tag_line,
                            description: this.description,
                            blogshowoption: this.blogshowoption,
                            price: this.price,
                            discount: this.discount,
                            vat: this.vat,
                            meta_title: this.meta_title,
                            meta_description: this.meta_description,
                            meta_keywords: this.meta_keyword,
                            created: date,
                            modified: date,
                        })
                        .then((doc) => {
                            console.log("Added Successfully!");
                            const filename = this.profileImg.name;
                            const ext = filename.slice(filename.lastIndexOf('.'));
                            const storageRef = storage.ref();
                            const fileRef = storageRef.child('subscriptions/' + doc.id + "." + ext);
                            fileRef.put(this.profileImg).then(result => {
                                fileRef.getDownloadURL().then(imageUrl => {
                                    console.log(imageUrl);
                                    db.collection("subscriptions").doc(doc.id).update({ image: imageUrl })
                                    store.dispatch('app/commitDeactivateLoader');
                                    this.$router.push({ name: 'subscription-index' });
                                    this.showNotification('Category Added Successfully!', 'AlignJustifyIcon', 'success');
                                }).catch((error) => {
                                    console.error("Error writing document: ", error);
                                    this.showNotification('Error! Request Failed!', 'AlignJustifyIcon', 'danger');
                                });
                            });
                        })
                })
            // var id = localStorage.getItem("userSessionId");
            // console.log('rrr ' + id);
        },
        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
      mounted() {
    this.readCategories();
    console.log(this.readCategories);
  }
}
</script>